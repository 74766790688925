::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
::selection {
	color: #fff;
	background:rgba(0, 0, 0, 0.4);
}
a {
  text-decoration: none;
}

ul {
  padding-left: 0;
}

img {
  max-width: 100%;
}

:focus-visible {
  outline: none;
}

.form-control:focus-visible {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
  /* border: none; */
}
.login-form {
  background-image: url(../public/images/login-bg.jpg);
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto;
}
.login-form .alert-danger {
  padding: 8px 15px;
  font-size: 12px;
}
.wrapper {
  position: relative;
  border: 2px solid rgba(255, 255, 255, 0.2);
  contain: layout style;
  will-change: transform;
  width: 38%;
  transform: rotateX(0deg) rotateY(0deg);
  background-position: 56.28% 46.11%;
  background-size: 100%;
  opacity: 1;
  border-radius: 35px;
  transition: 5s ease;
}
.login-form .form {
  box-shadow: inset 0 -20px 80px -20px #8686f01f;
  will-change: transform;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  border-color: hsla(0, 0%, 100%, 0.2);
  border-width: 0.1px;
  overflow: hidden;
  transform-origin: center;
  height: 100%;
  display: grid;
  border-radius: 33px;
  transform: rotateY(0deg) rotateX(0);
}
.login-form .form:hover {
  transition: 200ms linear;
  filter: none;
}
.form-bigbox {
  width: 100%;
  height: 100%;
  display: grid;
  grid-area: 1 / 1;
  clip-path: inset(0 0 0 0 round 23px);
}
.formblock {
  background-image: radial-gradient(
      ellipse 50% 80% at 20% 40%,
      rgba(93, 52, 221, 0.1),
      transparent
    ),
    radial-gradient(
      ellipse 50% 80% at 80% 50%,
      rgba(120, 119, 198, 0.15),
      transparent
    );
  width: 100%;
  height: 100%;
}
.form-inner-block {
  box-shadow: inset 0 -20px 80px -20px #8686f01f;
  padding: 35px 52px 22px;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0) rotate(0) skewX(0) skewY(0) scaleX(1)
    scaleY(1);
}
.screen-effect {
  display: grid;
  grid-area: 1 / 1;
  clip-path: inset(0 0 1px 0 round 23px);
  background: radial-gradient(
    farthest-corner circle at 67.47619598319262% 82.72758152173913%,
    hsla(0, 0%, 100%, 0.8) 10%,
    hsla(0, 0%, 100%, 0.65) 20%,
    hsla(0, 0%, 100%, 0) 90%
  );
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  mix-blend-mode: soft-light;
  opacity: 0.12;
  width: 100%;
  height: 100%;
}
.login-form .glass-effect {
  background: radial-gradient(
      ellipse 50% 80% at 20% 40%,
      rgba(93, 52, 221, 0.1),
      transparent
    ),
    radial-gradient(
      ellipse 50% 80% at 80% 50%,
      rgba(120, 119, 198, 0.15),
      transparent
    ),
    repeating-linear-gradient(
        45deg,
        rgba(255, 119, 115, 0.5) calc(5% * 1),
        rgba(255, 237, 95, 0.5) calc(5% * 2),
        rgba(168, 255, 95, 0.5) calc(5% * 3),
        rgba(131, 255, 247, 0.5) calc(5% * 4),
        rgba(120, 148, 255, 0.5) calc(5% * 5),
        rgba(216, 117, 255, 0.5) calc(5% * 6),
        rgba(255, 119, 115, 0.5) calc(5% * 7)
      )
      0% 80.81% / 200% 700% no-repeat,
    repeating-linear-gradient(
        128deg,
        #0e152e 0%,
        hsl(180, 10%, 60%) 3.8%,
        hsl(180, 10%, 60%) 4.5%,
        hsl(180, 10%, 60%) 5.2%,
        #0e152e 10%,
        #0e152e 12%
      )
      60.04% 65.07% / 300% no-repeat,
    radial-gradient(
        farthest-corner circle at 101.52% 64.25%,
        rgba(255, 255, 255, 0.1) 12%,
        rgba(255, 255, 255, 0.15) 20%,
        rgba(255, 255, 255, 0.25) 120%
      )
      62.88% 54.74% / 300% no-repeat;
  background-blend-mode: hue, hue, hue, overlay;
  box-shadow: inset 0 -20px 80px -20px #8686f01f;
  opacity: 0.2;
  mix-blend-mode: color-dodge;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-area: 1 / 1;
  cursor: pointer;
}
.login-form .login-card::after {
  content: "";
  background-size: 100%, 200% 400%, 800%, 200%;
  background-position: 50%, 0 59.81890398550725%,
    calc(61.9554951620986% * -1) calc(59.81890398550725% * -1),
    61.9554951620986% 59.81890398550725%;
  mix-blend-mode: exclusion;
  background-blend-mode: soft-light, hue, hard-light;
  background-color: inherit;
}
.login-form .form .formbox h1 {
  font-size: 28px;
  margin-bottom: 22px;
  color: #fff;
}
.login-form .form .formbox .form-label {
  color: #fff;
  font-size: 15px;
  margin-bottom: 5px;
}

.login-form .form .formbox .form-control {
  height: 42px;
  line-height: 42px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
}
.login-form .form .formbox .form-control::placeholder {
  color: #fff;
}
.login-form .form .formbox input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 100px rgba(255, 255, 255, 0.1) inset;
  transition: background-color 5000s ease-in-out 0s;
}
.login-form .form .formbox .form-control:focus {
  border-color: rgba(255, 255, 255, 0.4);
}
.login-form .form .formbox a {
  color: #fff;
}

.login-form .form .formbox p {
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.login-form .form .formbox .btn {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  width: 50%;
  margin: auto;
}

.login-form .form .formbox .other-opt {
  color: #fff;
  text-align: center;
  margin: 22px 0;
  font-size: 14px;
}

.login-form .form .formbox ul {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

.login-form .form .formbox ul li {
  list-style: none;
}

.login-form .form .formbox ul li a {
  background-color: #fff;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 5px;
  text-align: center;
}

.login-form .form .formbox ul li a img {
  max-width: 30%;
}

.otp-input {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.otp-input input {
  width: 50px;
  height: 50px;
  margin: 0 8px;
  text-align: center;
  font-size: 18px;
  border-radius: 12px;
  background-color: #fff;
  transition: all 0.3s ease;
  border: none;
}

.otp-input input:focus {
  box-shadow: none;
  outline: none;
}

.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input input[type="number"] {
  -moz-appearance: textfield;
}

.resend-link {
  background-color: transparent;
  color: #fff;
  border: none;
  text-decoration: underline;
}

.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  margin-bottom: 14px;
  font-size: 20px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  -webkit-animation: showSuccess 200ms ease-in-out;
  animation: showSuccess 200ms ease-in-out;
  transform: scale(1);
}

.success-icon__tip,
.success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  -webkit-animation: tipInPlace 300ms ease-in-out;
  animation: tipInPlace 300ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  -webkit-animation: longInPlace 140ms ease-in-out;
  animation: longInPlace 140ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 440ms;
  animation-delay: 440ms;
}

@-webkit-keyframes showSuccess {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@-webkit-keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }

  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }

  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@-webkit-keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }

  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }

  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}

.app-container {
  /* background-image: url(../public/images/space1.jpg); */
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #9672b2 0%,
    #000000 50%,
    #344f63 100%
  );
  /* background-color: #ea51a8;
  background-image: linear-gradient(225deg, #e66db1 0%, #784BA0 50%, #2B86C5 100%); */
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container .bigbox {
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(255, 255, 255, 0.1); */
  backdrop-filter: blur(4x);
  -webkit-backdrop-filter: blur(4px);
  padding: 1%;
  overflow: hidden;
}

.app-container .bigbox .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
  height: 8%;
}
.logobox{
  display: flex;
  width: 20%;
  column-gap: 15px;
  align-items: center;
}
.toggle {
  border: none;
  font-size: 18px;
  color: #fff;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.app-container .bigbox .header .logo .logo-image {
  max-width: 134px;
}
.app-container .bigbox .header .tabs {
  display: flex;
  gap: 20px;
  /* width: 62%; */
  width: 67%;
}
.app-container .bigbox .header .tabs .tab {
  cursor: pointer;
  color: #fff;
  padding: 6px 25px;
  border-radius: 25px;
}

.app-container .bigbox .header .tabs .tab:hover,
.app-container .bigbox .header .tabs .tab.active {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
/* Offcanvas styling */
.app-container .bigbox .header .tabs.offcanvas-open {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  height: 100%;
  width: 70%;
  flex-direction: column;
  gap: 30px;
  transition: right 0.3s ease, visibility 0.3s ease;
  transform: translateX(0); /* Open */
  z-index: 9999;
}
/* .app-container .bigbox .header .tabs {
  transform: translateX(100%); 
} */
.app-container .bigbox .header .tabs .offcanvas-close {
  display: none;
}
.app-container .bigbox .header .tabs.offcanvas-open .offcanvas-close {
  position: relative;
  font-size: 22px;
  cursor: pointer;
  color: #fff;
  padding: 2px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: right;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  transition: right 0.3s ease, visibility 0.3s ease;
}
.app-container .bigbox .header .tabs-toggle {
  display: none;
}
.app-container .bigbox .header .dropdown {
  width: 10%;
}

.app-container .bigbox .header .user-profile {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 5px 10px 5px 5px;
  border-radius: 50px;
  border: none;
  width: 100%;
}

.notify-box {
  width: 5% !important;
  text-align: right;
  position: relative;
}

.app-container .bigbox .header .notify-box .notification {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  color: #fff;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border-radius: 100%;
  text-align: center;
  border: none;
}

.app-container
  .bigbox
  .header
  .notify-box
  .notification.dropdown-toggle::after {
  display: none;
}

.app-container .bigbox .header .notify-box .dropdown-menu {
  width: 300px;
  transform: translate(50%, 50px) !important;
}

.app-container .bigbox .header .notify-box .green-dot {
  background-color: #1cdd4d;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  position: absolute;
  right: 9px;
  top: -1px;
}

.app-container .bigbox .header .notify-box .dropdown-item {
  background-color: #fff;
  border-radius: none;
  padding: 14px 17px !important;
  color: #000 !important;
}

.app-container .bigbox .header .notify-box .dropdown-item:hover {
  background-color: #fff !important;
}

.app-container .bigbox .header .user-profile .user-avatar {
  width: 30%;
}

.app-container .bigbox .header .user-profile .user-avatar img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.app-container .bigbox .header .user-profile .user-name {
  color: #fff;
  font-size: 14px;
  width: 70%;
  margin-left: 5%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.app-container .bigbox .header .dropdown-menu {
  animation: fadeIn 1s;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: none;
}

.app-container .bigbox .header .dropdown-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.app-container .bigbox .header .dropdown-menu li span {
  color: #fff;
  padding: 10px 17px;
  font-size: 16px;
  display: block;
  background-color: #000;
}

.app-container .bigbox .header .dropdown-menu li:last-child {
  border-bottom: none;
}

.app-container .bigbox .header .dropdown-menu li .dropdown-item {
  color: #fff;
  padding: 8px 17px;
  font-size: 13px;
}

.app-container .bigbox .header .dropdown-menu li .dropdown-item i {
  margin-right: 5px;
}

.app-container .bigbox .header .dropdown-menu .dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}

.app-container .bigbox .header .dropdown-menu .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

.app-container .bigbox .header .dropdown-toggle::after {
  color: #fff;
}

.app-container .bigbox .header .user-profile .user-name span {
  color: #cfcfcf;
  font-size: 11px;
  display: block;
}

.app-container .bigbox .smallbox {
  display: flex;
  height: 89.5%;
  position: relative;
}

.sidebar {
  position: absolute;
  left: 0;
  transition: left 0.3s ease, visibility 0.3s ease;
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 20px;
  height: 90%;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.sidebar.hidden {
  left: -100%;
}
.sidebar.visible {
  left: 0;
  transition: left 0.1s ease, visibility 0.1s ease;
}
.sidebar h3 {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.sidebar .sidebar-header {
  margin-bottom: 15px;
  column-gap: 15px;
}


.sidebar form .search-box {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
}

.sidebar .search-bar {
  padding: 0 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 80%;
  height: 38px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 12px;
  color: #fff;
}

.sidebar form .search-box button {
  width: 20%;
  height: 38px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  color: #fff;
  font-size: 13px;
  border: none;
  cursor: pointer;
  padding: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.sidebar .search-bar::placeholder {
  color: #fff;
  font-size: 12px;
}

.sidebar .search-bar:focus-visible {
  outline: none !important;
}

.chat-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 0 5px 0 0;
}

.chat-list::-webkit-scrollbar {
  width: 5px;
}

.chat-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.chatbox {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 6px 0;
}

.chat-item {
  padding: 8px 14px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  border-radius: 10px;
}

/* .chat-name {
  color: #000929;
  font-weight: 500;
  font-size: 15px;
} */

.chat-message {
  color: #fff;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new-chat {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  height: 8%;
  line-height: 8%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -11%;
}

.main-chat-window {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: right 0.3s ease, visibility 0.1s ease;
}
.main-chat-window.open {
  width: 80%;
  margin-left: 21%;
  transition: right 0.1s ease, visibility 0.1s ease;
}
.main-chat-window.hide {
  width: 100%;
  margin-left: 0;
}
.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: white;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.chat-area {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 100%;
  position: relative;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.chat-area .zoom-in {
  animation: zoomIn 0.3s forwards;
}
.chat-area .zoom-out {
  animation: zoomOut 0.3s forwards;
}
.start-button {
  min-height: 60px;
  max-width: 200px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}
.start-button,
.btn-glow {
  position: absolute;
  overflow: hidden;
  z-index: 0;
  max-height: 60px;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  border: none;
  background-color: transparent;
}
.start-button::before,
.btn-glow::before {
  position: absolute;
  content: "";
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #fff,
    rgba(0, 0, 0, 0) 40%
  );
  background-repeat: no-repeat;
  background-position: 0 0;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -2;
  animation: glow 3s linear infinite;
  filter: blur(32.5px);
}
.btn-glow {
  filter: blur(25px);
}
.start-button::after {
  position: absolute;
  content: "";
  z-index: -1;
  left: 3px;
  top: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #000;
  border-radius: 50px;
}
/* .start-button:hover::before{
  background-image:linear-gradient(133deg, rgba(118, 148, 255, 0.6) 50%, rgba(190, 153, 255, 0.6) 50%);
  filter: blur(32px);
}
.btn-glow:hover::before{
  filter: blur(32px);
} */
@keyframes glow {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 500px;
  transform: translateY(-50px);
  opacity: 0;
  animation: slideIn 0.3s ease-out forwards;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  margin-top: 20px;
}

.btn-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.chat-area .chat-box {
  height: 100%;
  width: 100%;
  position: relative;
  transition: width 0.3s ease;
}

.chat-area .chat-box .chats {
  height: 85%;
  overflow-y: auto;
  padding: 10px;
}

.chat-area .chat-box .chats .time {
  font-size: 14px;
  color: #cfcfcf;
  text-align: center;
  margin-bottom: 30px;
}

.chat-area .chat-box .chats p {
  margin-bottom: 0;
  color: #fff;
}

.user-message {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 9px 12px;
  margin-bottom: 12px;
  width: 60%;
  max-width: fit-content;
  border-radius: 10px;
  border-left: 4px solid #fff;
  color: #fff;
  overflow: hidden;
}

.your-message {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 9px 12px;
  margin-bottom: 12px;
  width: 60%;
  max-width: fit-content;
  border-radius: 10px;
  border-right: 4px solid #000;
  margin-left: auto;
  margin-right: 0;
  color: #fff;
  overflow: hidden;
}

.chat-area .chat-box .chats pre {
  margin-top: 10px !important;
  background-color: #000 !important;
  margin-bottom: 0 !important;
}

.chat-area .chat-box .chats pre code {
  white-space: pre-wrap !important;
}

.code-area {
  padding: 12px;
  height: 88.5%;
  overflow-y: auto;
}
.code-area h3 {
  background-color: #2a2a2a;
  color: #fff;
  font-size: 11px;
  font-weight: 100;
  padding: 5px 16px;
  margin-bottom: -10px;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-area .code-area pre {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: #000 !important;
  margin-bottom: 0 !important;
  overflow: auto !important;
}
.code-area pre::-webkit-scrollbar {
  height: 10px !important;
}
.code-area .copy-button {
  background-color: transparent;
  color: #cdcccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.code-area .copy-button i {
  margin-right: 5px;
}
.code-area .copy-button:hover {
  color: #fff;
}

.code-area .copy-button:focus {
  outline: none;
}

.chat-area .chat-box .message-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}
.tablebox {
  margin: 10px 0;
  height: 225px;
  overflow-y: auto;
}
.tablebox .table {
  margin-bottom: 0;
}
.tablebox .table thead tr th {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff;
  font-size: 12px;
  border: none;
  text-align: center;
}
.tablebox .table tbody tr th, td {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
  font-size: 12px;
  border: none;
  text-align: center;
}
.tablebox .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.generated-img {
  margin: 10px 0;
}
.generated-img img {
  max-width: 100%;
}
.chat-area .chat-box .message-box .msg-area {
  width: 96%;
}

.chat-area .chat-box .message-box .msg-area .message {
  padding: 10px 15px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 100%;
  height: 45px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
}

.chat-area .chat-box .message-box .msg-area .message::placeholder {
  color: #fff;
}

.chat-area .chat-box .message-box .msg-btn {
  width: 6%;
}

.chat-area .chat-box .message-box button {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: none;
  height: 45px;
  padding: 0 16px;
  color: #fff;
  font-size: 20px;
  width: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.chat-box.drawer-open {
  width: calc(100% - 44%);
}
.chat-area .chat-box.drawer-open .message-box .msg-area {
  width: 89%;
}
.chat-area .chat-box.drawer-open .message-box .msg-btn {
  width: 12%;
}
.toggle-drawer-button {
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: none;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  display: block;
  margin: 10px 0;
}
.toggle-drawer-button img {
  max-width: 115px;
}
.toggle-drawer-button i {
  margin-right: 5px;
}
.right-drawer {
  position: fixed;
  top: 20px;
  right: -100%;
  width: 40%;
  height: 92%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  transition: right 0.3s ease, visibility 0.3s ease;
  visibility: hidden;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.right-drawer.visible {
  right: 20px;
  visibility: visible;
}
.right-drawer.csvvisible {
  right: 20px;
  visibility: visible;
}
.drawer-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.drawer-heading h2 {
  font-size: 13px;
  margin-bottom: 0;
  color: #fff;
}
.close-drawer-button {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.db-source-box {
  width: 80%;
  margin: auto;
}

.db-source-box h3 {
  color: #fff;
  font-size: 22px;
  margin: 20px 0 45px;
  text-align: center;
}

.data-source {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.data-source .upload-box {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.custom-file-upload {
  padding: 15px 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 30px !important;
  text-align: left;
  color: #fff;
  border: none;
  /* width: 100%; */
}

.custom-file-upload:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.upload-icon {
  margin-right: 20px;
  font-size: 15px;
}

.form-container {
  width: 100%;
  height: 100%;
}

.upload-files-container {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 30px 60px;
  border-radius: 40px;
  text-align: center;
  position: relative;
}

.upload-files-container .btn-box {
  position: absolute;
  bottom: 4%;
  right: 4%;
}

.drag-file-area {
  border: 2px dashed #fff;
  border-radius: 18px;
  margin: 10px 0 22px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.drag-file-area .upload-icon {
  font-size: 50px;
  color: #fff;
}

.drag-file-area h3 {
  font-size: 20px;
  margin: 15px 0;
  color: #fff;
}

.drag-file-area label {
  font-size: 19px;
  color: rgba(255, 255, 255, 0.7);
}

.drag-file-area label .browse-files-text {
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.browse-files span {
  position: relative;
  color: rgba(255, 255, 255, 0.7);
}

.default-file-input {
  opacity: 0;
  display: none;
}

.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px auto;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #bb0000;
  justify-content: center;
  width: fit-content;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}

.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}

.file-block {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  color: #fff;
  transition: all 1s;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.file-icon {
  margin-right: 5px;
  font-size: 19px;
}

.file-name,
.file-size {
  padding: 0 3px;
  font-size: 12px;
}

.remove-file-icon {
  cursor: pointer;
  font-size: 17px;
}

.progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 3%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4bb543;
}

.upload-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;
  margin: 10px auto;
  padding: 7.5px 50px;
  cursor: pointer;
  margin-top: 15px;
}

.previous-ds {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 24px 30px;
  border-radius: 15px;
}

.previous-ds h4 {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-bottom: 18px;
}

.previous-ds .ds-box {
  overflow-y: auto;
  height: 156px;
}

.previous-ds .form-check-label {
  color: #fff;
  margin-left: 10px;
}

.previous-ds .form-check-input {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
}

.previous-ds .form-check-input:focus {
  box-shadow: none;
}

.previous-ds .connect-btn {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 45px;
  margin-top: 20px;
  border-radius: 30px;
  width: 100%;
}

.database-list {
  position: relative;
  height: 100%;
  width: 80%;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.database-list h3 {
  color: #fff;
  font-size: 22px;
  margin: 20px 0 30px;
}

.database-list .db-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow-y: auto;
  height: 65%;
  padding: 0 5px;
}

.database-list .db-box a {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 15px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  border-radius: 20px;
}

.db-icon {
  background-color: #fff;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.db-icon img {
  max-width: 32px;
}

.database-list .db-box a span {
  font-size: 18px;
  color: #fff;
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
}

.database-list .db-box a small {
  font-size: 13px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 20px;
  border-radius: 25px;
  height: 25px;
  display: inline-block;
  line-height: 23px;
}

.database-list .btn-box {
  margin-top: 30px;
  text-align: right;
}

.buttons {
  font-size: 15px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 8px 25px;
  border-radius: 20px;
}

.db-details {
  width: 80%;
  margin: auto;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.db-details .db-heading {
  display: flex;
  align-items: center;
  column-gap: 18px;
  margin: 5px 0 20px;
}

.db-details .db-heading .db-icon {
  width: 45px;
  height: 45px;
}

.db-details .db-heading h3 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
}

.db-form h4 {
  color: #fff;
  font-size: 17px;
  margin-bottom: 18px;
}

.db-form span {
  color: #fff;
  font-size: 11px;
}

.db-form input {
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  height: 40px;
  line-height: 40px;
}

.db-form input::placeholder {
  font-size: 14px;
  color: #fff;
}

.db-form .form-control:focus {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.5);
  height: 40px;
}

.db-details .btn-box {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.db-details .btn-box .contect {
  background-color: rgba(0, 0, 0, 0.3);
}