@media (min-width: 2001px) and (max-width: 2600px) {
  .app-container .bigbox .header .user-profile .user-avatar {
    width: 20%;
  }
  .wrapper {
    width: 25%;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  .right-drawer {
    height: 95.5%;
  }
  .app-container .bigbox .header .user-profile .user-avatar {
    width: 20%;
  }
}
@media (min-width: 1025px) and (max-width: 1365px) {
  .new-chat {
    bottom: -9%;
  }
  .sidebar {
    height: 91.5%;
  }
  .app-container .bigbox .header .dropdown {
    width: 12%;
  }
  .app-container .bigbox .header .tabs {
    width: 65%;
  }
  .right-drawer {
    height: 96%;
  }
}
@media (min-width: 993px) and (max-width: 1024px) {
  .wrapper {
    width: 60%;
  }
  .sidebar {
    width: 25%;
    height: 93%;
  }
  .main-chat-window.open {
    margin-left: 26%;
  }
  .logobox {
    width: 28%;
  }
  .app-container .bigbox .header .dropdown {
    width: 15%;
  }
  .app-container .bigbox .header .tabs {
    width: 63%;
  }
  .new-chat {
    height: 6%;
    bottom: -7.5%;
  }
  .upload-files-container {
    width: 100%;
    border-radius: 18px;
  }
  .chat-area .chat-box .message-box .msg-area {
    width: 90%;
  }
  .chat-area .chat-box .message-box .msg-btn {
    width: 10%;
  }
  .right-drawer {
    height: 96.5%;
  }
}
@media (max-width: 992px) {
  .app-container .bigbox .header .tabs {
    display: none;
  }
  .app-container .bigbox .header .tabs .offcanvas-close {
    display: block;
  }
  .app-container .bigbox .header .tabs-toggle {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.4);
  }

  .app-container .bigbox .header .tabs.offcanvas-open {
    display: block;
  }
  .app-container .bigbox .header .tabs .tab {
    border-radius: 0px;
    padding: 9px 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .app-container .bigbox .header .tabs .tab:hover,
  .app-container .bigbox .header .tabs .tab.active {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .app-container .bigbox .header .user-profile .user-name {
    display: none;
  }
  .app-container .bigbox .header .user-profile .user-avatar {
    width: 80%;
  }
  .app-container .bigbox .header .dropdown {
    width: 18%;
  }
  .sidebar {
    width: 75%;
    height: 100%;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  .sidebar.hidden {
    left: 0;
    z-index: 9;
  }
  .sidebar.visible {
    left: -100%;
  }
  .new-chat {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    position: relative;
    bottom: 0;
  }
  .main-chat-window.open {
    width: 100%;
    margin-left: 0;
  }
  .main-chat-window.hide {
    width: 100%;
    margin-left: 0;
  }
  .start-button,
  .btn-glow {
    max-height: 40px;
    max-width: 155px;
  }
  .start-button {
    min-height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .wrapper {
    width: 80%;
  }
  .app-container .bigbox .header .logo .logo-image {
    max-width: 108px !important;
  }
  .toggle {
    font-size: 15px !important;
  }
  .app-container .bigbox .header .user-profile .user-avatar {
    width: 34% !important;
  }
  .app-container .bigbox .header .user-profile .user-avatar img {
    width: 35px;
    height: 35px;
  }
  .app-container .bigbox .header .user-profile .user-name {
    display: block !important;
  }
  .app-container .bigbox .header .dropdown {
    width: 20% !important;
  }
  .logobox {
    width: 70% !important;
  }
  .app-container .bigbox .header .tabs-toggle {
    font-size: 34px !important;
  }
  .file-name {
    width: auto !important;
    text-align: left;
  }
  .progress-bar {
    width: 94% !important;
    left: 3% !important;
  }
  .chat-area .chat-box .message-box .msg-area {
    width: 92% !important;
  }
  .chat-area .chat-box .message-box .msg-btn {
    width: 10% !important;
    contain: layout style;
  }
  .chat-area .chat-box .chats {
    height: 92% !important;
  }
  .sidebar {
    width: 50% !important;
  }
  .app-container .bigbox .smallbox {
    height: 90%;
  }
}
@media (max-width: 768px) {
  .logobox {
    width: 66%;
  }
  .app-container .bigbox .header .logo .logo-image {
    max-width: 100px;
  }
  .toggle {
    font-size: 16px;
  }

  .custom-file-upload {
    padding: 13px 22px;
    font-size: 14px;
  }
  .upload-icon {
    margin-right: 10px;
  }
  .chat-area {
    padding: 13px;
  }
  .upload-files-container {
    width: 100%;
    padding: 15px;
    border-radius: 16px;
  }
  .drag-file-area {
    border-radius: 8px;
    margin: 0px 0 22px;
    padding: 20px 25px;
  }
  .drag-file-area .upload-icon {
    margin-right: 0;
    font-size: 30px;
  }
  .drag-file-area h3 {
    font-size: 16px;
    margin: 10px 0;
  }
  .drag-file-area span {
    font-size: 14px;
  }
  .upload-button {
    font-size: 13px;
  }
  .buttons {
    font-size: 13px;
  }
  .file-block {
    padding: 7px 13px;
  }
  .file-info {
    font-size: 13px;
    width: 80%;
  }
  .file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50%;
  }
  .chat-area .chat-box .message-box .msg-area {
    width: 90%;
  }
  .chat-area .chat-box .message-box .msg-btn {
    width: 20%;
  }
  .chat-area .chat-box .message-box .msg-area .message {
    height: 40px;
    font-size: 13px;
  }
  .chat-area .chat-box .message-box button {
    padding: 0;
    height: 40px;
    font-size: 16px;
  }
  .chat-area .chat-box .chats {
    padding: 5px;
    height: 88%;
  }
  .user-message {
    width: 75%;
    font-size: 12px;
  }
  .your-message {
    width: 75%;
    font-size: 12px;
  }
  .toggle-drawer-button img {
    max-width: 90px;
  }
  .chat-box.drawer-open {
    width: calc(100% - 0%);
  }
  .right-drawer.visible {
    right: 0px;
  }
  .right-drawer {
    top: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 99;
  }
  .drawer-heading {
    backdrop-filter: blur(10px);
  }
}

@media (max-width: 767px) {
  .wrapper {
    width: 94%;
    border-radius: 25px;
  }
  .form-inner-block {
    padding: 26px 26px 14px;
  }
  .login-form {
    overflow-y: auto;
  }
  .login-form .form {
    border-radius: 25px;
  }
  .login-form .form .formbox h1 {
    font-size: 24px;
  }
  .login-form .form .formbox .other-opt {
    margin: 13px 0;
  }
  .login-form .form .formbox .btn {
    width: 65%;
  }
  .otp-input {
    justify-content: space-between;
  }
  .otp-input input {
    margin: 0;
  }

  .progress-bar {
    width: 90% !important;
    left: 5%;
  }
}
@media (max-width: 500px) and (orientation: portrait) {
  .user-message {
    width: 90%;
  }
  .your-message {
    width: 90%;
  }
}
